import { useState } from "react"
import { useContext, createContext } from "react"
import DefaultSnackbar from "./DefaultSnackbar"

const SnackbarContext = createContext()

export function useSnackbar() {
    const context = useContext(SnackbarContext)
    if (context === undefined) {
        throw new Error("useSnackbar must be used within a SnackbarProvider")
    }
    return context
}

export default function SnackbarProvider({ children }) {
    const [isOpen, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [isError, setError] = useState(false)

    const onSuccess = (message) => {
        setMessage(message)
        setError(false)
        setOpen(true)
    }

    const onError = (message) => {
        setMessage(message)
        setError(true)
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }
    return (
        <SnackbarContext.Provider
            value={{
                onSuccess,
                onError
            }}
        >
            {children}
            <DefaultSnackbar
                isOpen={isOpen}
                onClose={onClose}
                message={message}
                isError={isError}
            />
        </SnackbarContext.Provider>
    )
}
