import { createContext, useState, useMemo } from "react"
import { createTheme } from "@mui/material/styles"

// color design tokens export
export const tokens = (mode) => ({
    ...(mode === "dark"
        ? {
              grey: {
                  600: "#525252",
                  700: "#3d3d3d",
                  800: "#292929",
                  900: "#202020",
                  100: "#e0e0e0",
                  200: "#c2c2c2",
                  300: "#a3a3a3",
                  400: "#858585",
                  500: "#666666"
              },
              primary: {
                  500: "#693BE1",
                  600: "#101624",
                  100: "#d0d1d5",
                  200: "#a1a4ab",
                  300: "#727681",
                  400: "#1F2A40",
                  700: "#0c101b",
                  800: "#080b12",
                  900: "#040509"
              },
              greenAccent: {
                  100: "#dbf5ee",
                  200: "#b7ebde",

                  600: "#3da58a",
                  700: "#2e7c67",
                  800: "#1e5245",
                  300: "#94e2cd",
                  400: "#70d8bd",
                  500: "#4cceac",
                  900: "#0f2922"
              },
              redAccent: {
                  100: "#f8dcdb",

                  500: "#db4f4a",
                  600: "#af3f3b",
                  700: "#832f2c",
                  200: "#f1b9b7",
                  300: "#e99592",
                  400: "#e2726e",
                  800: "#58201e",
                  900: "#2c100f"
              },
              blueAccent: {
                  100: "#f0bd97",

                  500: "#db9b69",
                  600: "#edac79",
                  200: "#de965d",
                  300: "#f0a66c",
                  400: "#db9863",
                  700: "#cc956a",
                  800: "#ebae7f",
                  900: "#f2b98d"
              }
          }
        : {
              grey: {
                  800: "#c2c2c2",
                  400: "#525252",
                  500: "#666666",
                  600: "#858585",
                  100: "#202020",
                  200: "#292929",
                  300: "#3d3d3d",
                  700: "#a3a3a3",

                  900: "#e0e0e0"
              },
              primary: {
                  100: "#040509",
                  800: "#a1a4ab",
                  400: "#f2f0f0", // manually changed
                  500: "#693BE1",
                  600: "#1F2A40",
                  200: "#080b12",
                  300: "#0c101b",
                  700: "#727681",

                  900: "#d0d1d5"
              },
              greenAccent: {
                  300: "#2e7c67",
                  400: "#3da58a",
                  500: "#4cceac",
                  600: "#70d8bd",
                  100: "#0f2922",
                  200: "#1e5245",
                  700: "#94e2cd",
                  800: "#b7ebde",
                  900: "#dbf5ee"
              },
              redAccent: {
                  100: "#2c100f",
                  700: "#e99592",
                  800: "#f1b9b7",
                  500: "#db4f4a",
                  600: "#e2726e",

                  900: "#f8dcdb",
                  200: "#58201e",
                  300: "#832f2c",
                  400: "#af3f3b"
              },
              blueAccent: {
                  700: "#f0a66c",
                  800: "#de965d",
                  500: "#db9b69",
                  600: "#db9863",
                  100: "#f2b98d",
                  200: "#ebae7f",
                  300: "#cc956a",
                  400: "#edac79",
                  900: "#f0bd97"
              }
          })
})

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode)
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                      // palette values for dark mode
                      primary: {
                          main: colors.primary[500]
                      },
                      secondary: {
                          main: "#1DB954"
                      },
                      neutral: {
                          dark: colors.grey[700],
                          main: colors.grey[500],
                          light: colors.grey[100]
                      },
                      background: {
                          default: colors.primary[500]
                      }
                  }
                : {
                      // palette values for light mode
                      primary: {
                          main: colors.primary[100]
                      },
                      secondary: {
                          main: "#1DB954" //colors.greenAccent[500],
                      },
                      customColor: {
                          main: "#693BE1"
                      },
                      neutral: {
                          dark: colors.grey[700],
                          main: colors.grey[500],
                          light: colors.grey[100]
                      },
                      background: {
                          default: "#fcfcfc"
                      },
                      brand: {
                          main: "#643dd6",
                          dark: "#4e2fb0",
                          light: "#7f60e0",
                          contrastText: "#ffffff"
                      },
                      yellow: {
                          50: "#fefce8",
                          100: "#fef3c7",
                          200: "#fde68a",
                          300: "#fcd34d",
                          400: "#fbbf24",
                          500: "#f59e0b",
                          600: "#d97706",
                          700: "#b45309",
                          800: "#92400e",
                          900: "#78350f"
                      },
                      red: {
                          50: "#fef2f2",
                          100: "#fee2e2",
                          200: "#fecaca",
                          300: "#fca5a5",
                          400: "#f87171",
                          500: "#ef4444",
                          600: "#dc2626",
                          700: "#b91c1c",
                          800: "#991b1b",
                          900: "#7f1d1d"
                      },
                      green: {
                          50: "#f0fdf4",
                          100: "#dcfce7",
                          200: "#bbf7d0",
                          300: "#86efac",
                          400: "#4ade80",
                          500: "#22c55e",
                          600: "#16a34a",
                          700: "#15803d",
                          800: "#166534",
                          900: "#14532d"
                      },
                      gray: {
                          50: "#f9fafb",
                          100: "#f3f4f6",
                          200: "#e5e7eb",
                          300: "#d1d5db",
                          400: "#9ca3af",
                          500: "#6b7280",
                          600: "#4b5563",
                          700: "#374151",
                          800: "#1f2937",
                          900: "#111827"
                      },
                      orange: {
                          50: "#fff7ed",
                          100: "#ffedd5",
                          200: "#fed7aa",
                          300: "#fdba74",
                          400: "#fb923c",
                          500: "#f97316",
                          600: "#ea580c",
                          700: "#c2410c",
                          800: "#9a3412",
                          900: "#7c2d12"
                      }
                  })
        },
        typography: {
            fontFamily: ["Mona Sans"].join(","),
            fontSize: 14,
            h1: {
                fontFamily: ["Mona Sans"].join(","),
                fontSize: 40
            },
            h2: {
                fontFamily: ["Mona Sans"].join(","),
                fontSize: 28,
                color: "#1E1E1E",
                fontWeight: 500
            },
            h3: {
                fontFamily: ["Mona Sans"].join(","),
                fontSize: 24
            },
            h4: {
                fontFamily: ["Mona Sans"].join(","),
                fontSize: 20
            },
            h5: {
                fontFamily: ["Mona Sans"].join(","),
                fontSize: 16
            },
            h6: {
                fontFamily: ["Mona Sans"].join(","),
                fontSize: 14
            }
        },
        button: {
            fontFamily: ["Mona Sans"].join(",")
        },
        p: {
            fontFamily: ["Mona Sans"].join(",")
        },
        span: {
            fontFamily: ["Mona Sans"].join(",")
        }
    }
}

// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => {}
})

export const useMode = () => {
    const [mode, setMode] = useState("light")

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
                setMode((prev) => (prev === "light" ? "dark" : "light"))
        }),
        []
    )

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
    return [theme, colorMode]
}
