import { Snackbar, Alert } from "@mui/material"

export default function DefaultSnackbar({ isOpen, onClose, message, isError }) {
    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={onClose}
            message={message}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            {isError && (
                <Alert
                    onClose={onClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            )}
        </Snackbar>
    )
}
