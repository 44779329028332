/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react"
import {
    Box,
    Stack,
    Divider,
    Grid,
    Typography,
    useMediaQuery
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
    setmodalState,
    setText,
    setButton,
    setchatBot,
    setOpenSide,
    setTargetValue,
    setOpenDrawer,
    setCloseDrawer
} from "../../redux/slices/navSlice"
import BordeauxIcon from "../../theme/images/NewSidebarIcons/BordeauxNew.svg"
import { ReactComponent as MenuOpenIcon } from "../../theme/images/MenuExpand.svg"
import { ReactComponent as MenuCollapseIcon } from "../../theme/images/MenuCollapse.svg"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import IconButton from "@mui/material/IconButton"
import navButton from "./navButtons"

import "./layout.css"

const bordeauxButtons = [
    {
        index: 35,
        text: "Bill Of Materials",
        path: "/billofmaterials"
    },
    {
        index: 36,
        text: "Configure Freight Charges",
        path: "/configureCharges"
    }
]

function SideNavbar() {
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const isTab = useMediaQuery(theme.breakpoints.up("md"))
    const locationPath = window.location.pathname
    const menuOpen = useSelector((state) => state.nav.openDrawer)
    const Button = useSelector((state) => state.nav.Button)
    const modal = useSelector((state) => state.nav.modalState)
    const isSm = useMediaQuery("(max-width:700px)")
    const isXlLarge = useMediaQuery("(min-width:2200px)")
    const user = JSON.parse(window.localStorage.getItem("user"))
    const familyOfficeType = window.localStorage.getItem("familyOfficeType")
    const userRights = user?.userRights
    const hniUser = user?.userTypeId?.description
    const largeScreenHeight = useMediaQuery("(min-height: 1300px)")
    const isHni = hniUser === "Client Head" || hniUser === "Client Family Member" ? true : false

    // use hard coded buttons from navButtons and remove Bordeaux if entity RM or hni
    const navButtons2 =
        isHni || user?.userTypeId?.description === "Entity RM"
            ? navButton
                .filter((val) => val.text !== "Live Trade - Bordeaux")
                .reduce((acc, item) => {
                    acc.push(item)
                    return acc
                }, [])
            : navButtons

    // load permissions from user.userrights
    const modulesWithPermissions =
        userRights?.filter(
            (permissionObj) =>
                permissionObj.create ||
                permissionObj.readOnly ||
                permissionObj.approve
        ) || []
    let moduleNames = modulesWithPermissions?.map((obj) =>
        obj?.module?.replace(/\s/g, "")
    )

    // here we remove the buttons that are not allowed from the user rights
    let NewButtons =
        navButtons2?.filter(
            (buttonObj) =>
                moduleNames.indexOf(buttonObj?.text?.replace(/\s/g, "")) !== -1
        ) || []

    // for Deal Room, replace the button text by Investments
    NewButtons = NewButtons?.map(buttonObj => {
        if (buttonObj.text === "Deal Room") {
            return {
                ...buttonObj,
                text: "Investments"
            };
        }
        if (buttonObj.text === "Chat with RM") {
            return {
                ...buttonObj,
                text: "Messages"
            };
        }
        if (buttonObj.text === "My Clients" && familyOfficeType === "SFO") {
            return {
                ...buttonObj,
                text: "My Entities"
            }
        }
        if (buttonObj.text === "My Prospects" && familyOfficeType === "SFO") {
            return {
                ...buttonObj,
                text: "Onboard Entity"
            }
        }
        return buttonObj;
    }) || [];

    // we do the same for Bordeaux button
    const bordButtons =
        bordeauxButtons?.filter(
            (buttonObj) =>
                moduleNames.indexOf(buttonObj?.text?.replace(/\s/g, "")) !== -1
        ) || []

    const [openBordeaux, setOpenBordeaux] = useState(false)
    const [sidebarButtons, setSidebarButtons] = useState([])

    const OpenMenuFromSidebar = async () => {
        dispatch(setOpenDrawer())
    }
    const CloseMenuFromSidebar = async () => {
        dispatch(setCloseDrawer())
    }

    useEffect(() => {
        // auto colapse the sidebar if tablet or below
        if (isTab) {
            dispatch(setOpenDrawer(false))
        }
        dispatch(setButton(NewButtons[0]))
        for (let i = 0; i < NewButtons.length; i++) {
            if (locationPath.includes(NewButtons[i]?.path)) {
                dispatch(setButton(NewButtons[i]))
                dispatch(setText(NewButtons[i].text))
                break
            } else if (locationPath.includes(bordButtons[i]?.path)) {
                dispatch(setButton(bordButtons[i]))
                dispatch(setText(bordButtons[i].text))
                break
            } else {
                const data = window.location.href.includes("/chatgpt")
                console.log(data, "test")

                if (window.location.href.includes("/chatgpt")) {
                    dispatch(setTargetValue("ChatGpt"))
                    dispatch(setchatBot(true))
                } else if (window.location.href.includes("/kairosbot")) {
                    dispatch(setTargetValue("llm"))
                    dispatch(setchatBot(true))
                } else if (window.location.href.includes("/chatgpt/history")) {
                    dispatch(setTargetValue("ChatGpt"))
                    dispatch(setchatBot(true))
                } else if (
                    window.location.href.includes("/kairosbot/history")
                ) {
                    dispatch(setTargetValue("llm"))
                    dispatch(setchatBot(true))
                } else {
                    dispatch(setchatBot(false))
                }
                dispatch(setButton({}))
                dispatch(setText(""))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /** Detect if mobile or not and adjust buttons for it */
    useEffect(() => {
        if (isMobile) {
            const newSidebarValue = NewButtons
            setSidebarButtons(newSidebarValue)
        } else {
            setSidebarButtons(NewButtons)
        }
        // eslint-disable-next-line
    }, [isMobile])

    const handleSideClose = () => {
        dispatch(setOpenSide(false))
    }

    return (
        <div>
            <Box sx={{ background: "#FFFFFF", width: menuOpen ? "100%" : "80px" }} >
                <Stack direction="column" alignItems="center">
                    {/** Kairos Logo and collapse icon*/}
                    {!isMobile ? (
                        <>
                            {!menuOpen ? (
                                <Grid
                                    sx={{
                                        width: "100%",
                                        height: "80px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingTop: "20px"
                                    }}
                                >
                                    <MenuCollapseIcon
                                        onClick={() => {
                                            OpenMenuFromSidebar()
                                        }}
                                        sx={{ cursor: "pointer" }}
                                    />
                                </Grid>
                            ) : (
                                <Grid
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "20px"
                                    }}
                                >
                                    <img
                                        src={user?.entityLogo}
                                        style={{
                                            width: "40px",
                                            objectFit: "contain",
                                            cursor: "pointer"
                                        }}
                                        alt="logoIcon"
                                        onClick={() => {
                                            navigate("/dashboard")
                                        }}
                                    />
                                    <Grid
                                        sx={{
                                            cursor: "pointer",
                                            textAlign: "right",
                                            pt: "4px"
                                        }}
                                    >
                                        <MenuOpenIcon
                                            onClick={() => { CloseMenuFromSidebar() }}
                                            sx={{ cursor: "pointer" }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    ) : null}

                    {/** Main stack with all buttons */}
                    <Stack spacing={0.1} mt={{ sm: "12px", xs: 1, lg: "12px", xl: "12px" }} direction="column"
                        sx={{
                            borderRadius: "0px",
                            maxHeight: isSm ? "85dvh" : largeScreenHeight ? "85dvh" : "74dvh",
                            overflowX: "hidden",
                            width: "100%",
                            overflowY: "auto",
                            "&::-webkit-scrollbar": { width: "3px" }
                        }}
                    >
                        {sidebarButtons?.map((button) =>
                            button.text === "Divider" ? (
                                <Divider
                                    key={button.index}
                                    style={{ margin: "20px 0px" }}
                                />
                            ) : (
                                <Box
                                    key={button.index}
                                    onClick={() => {
                                        dispatch(setButton(button))
                                        if (button.text === !"RM Performance") {
                                            dispatch(setText(button.text))
                                        }
                                        handleSideClose()
                                        navigate(button.path)
                                        if (!button.path) {
                                            dispatch(setmodalState(true))
                                        }
                                    }}
                                    className="navList"
                                    sx={
                                        button.path === Button?.path ? {
                                            backgroundColor: !locationPath.includes("/prospectprofile") && !modal ? "#F0EBFF" : null,
                                            color: "#643DD6"
                                        } : { ":hover": { backgroundColor: "#ebebeb" }, color: "#1E1E1E" }
                                    }
                                >
                                    {menuOpen ? (
                                        <Grid container style={{ padding: "10px 20px 10px 20px" }} sx={{ maxWidth: "100%", overflow: "hidden" }}>
                                            <Grid item pt={0} pr={1}>
                                                <Typography sx={{ display: "flex", alignItems: "center" }}>
                                                    <img src={button.path === Button?.path ? button?.icon3 : button?.icon2} alt=""
                                                        style={{ width: "24px", height: "24px", fontFamily: "Mona Sans" }}
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item pt={0.7} sx={{ minWidth: 0, flex: 1, overflow: "hidden" }}>
                                                <Typography pt={0}
                                                    title={button.text}
                                                    style={{
                                                        fontFamily: "Mona Sans",
                                                        fontSize: "14px",
                                                        fontWeight: 500,
                                                        lineHeight: "16.8px",
                                                        textAlign: "left",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        maxWidth: "200px",
                                                        display: "block"
                                                    }}
                                                >
                                                    {button.text}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container justifyContent={"center"} alignItems={"center"}>
                                            <Grid item style={{ padding: "10px 20px 10px 20px" }} >
                                                <Typography className="icon">
                                                    <img src={button.path === Button?.path ? button?.icon3 : button?.icon2} alt=""
                                                        style={{ width: "24px", height: "24px", fontFamily: "Mona Sans" }}
                                                    />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Box>
                            )
                        )}

                        {/** For investors users, we want to add non clickable menu items that showcase other platform capabilities */}
                        {user?.userTypeId?.description === "Investor" && (
                            <Box key="dummy1" className="navList"
                                sx={{ border: "2px dashed #ebebeb", borderRadius: "8px", margin: "5px 10px !important" }}
                            >
                                {menuOpen ? (
                                    <Grid container style={{ color: "#9b9b9b", padding: "10px 20px 10px 20px" }}>
                                        <Grid item pt={0} pr={1}>
                                            <Typography>
                                                <a
                                                    href="https://kairoswealth.com/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        color: "#9b9b9b",
                                                        textDecoration: "none",
                                                        fontWeight: "normal",
                                                        fontStyle: "normal"
                                                    }}
                                                >
                                                    Learn More About Our Other Modules
                                                </a>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container style={{ color: "#9b9b9b", padding: "10px 20px 10px 20px" }}>
                                        <Grid item pt={0} pr={1}>
                                            <Typography>
                                                &nbsp;
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        )}

                        {/** bordeauxButtons for admin panel: Bill Of Material and Configure freight charge */}
                        {bordButtons?.length > 0 && menuOpen && (
                            <Box className="navlist" sx={{ color: "#1E1E1E" }}>
                                <Grid container pl={2}>
                                    <Grid item xl={isXlLarge ? 1 : 2} lg={2} sm={2} md={2} pt={0} pr={1} >
                                        <Typography>
                                            <img
                                                src={BordeauxIcon}
                                                alt="BordeauxIcon"
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    fontFamily: "Mona Sans"
                                                }}
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={9} xl={isXlLarge ? 9 : 8} sm={8} md={8} pt={0.2} >
                                        <Typography
                                            pt={0}
                                            style={{
                                                fontFamily: "Mona Sans",
                                                fontSize: "14px",
                                                fontWeight: 600,
                                                lineHeight: "16.8px",
                                                textAlign: "left",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "160px",
                                                display: "block"
                                            }}
                                            onClick={() =>
                                                setOpenBordeaux(!openBordeaux)
                                            }
                                        >
                                            Bordeaux ({bordButtons?.length})
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xl={isXlLarge ? 1 : 2} sm={2} md={2} pt={0.2} >
                                        <IconButton
                                            onClick={() => setOpenBordeaux(!openBordeaux)}
                                            aria-label="expand"
                                            size="small"
                                            sx={{
                                                color: "#1E1E1E",
                                                paddingBlock: "0px",
                                                alignItems: "start",
                                                fontSize: "14px",
                                                marginTop: "-2px",
                                                marginLeft: "-4px"
                                            }}
                                        >
                                            {openBordeaux ? (<KeyboardArrowDownIcon />) : (<KeyboardArrowRightIcon />)}
                                        </IconButton>
                                    </Grid>

                                    {openBordeaux && (
                                        <Grid container pl={2} sx={{ maxWidth: "100%", width: "100%" }}>
                                            {bordButtons?.map((button) => {
                                                return (
                                                    <Grid item key={button?.text} lg={12} xl={12} sm={12} md={12} xs={12} pt={0.5} pl={4.5} pb={1} sx={{ width: "100%", minWidth: 0 }}>
                                                        <Typography
                                                            title={button?.text}
                                                            sx={button.path === Button?.path ? {
                                                                backgroundColor: "#F0EBFF",
                                                                color: "#1E1E1E",
                                                                borderRadius: "5px",
                                                                paddingInline: "8px",
                                                                paddingBlock: "6px",
                                                                cursor: "pointer",
                                                                fontFamily: "Mona Sans",
                                                                fontSize: "14px",
                                                                fontWeight: 500,
                                                                lineHeight: "16.8px",
                                                                textAlign: "left",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "200px",
                                                                display: "block"
                                                            } : {
                                                                color: "#1E1E1E",
                                                                fontFamily: "Mona Sans",
                                                                fontSize: "14px",
                                                                fontWeight: 500,
                                                                lineHeight: "16.8px",
                                                                textAlign: "left",
                                                                cursor: "pointer",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "200px",
                                                                display: "block"
                                                            }
                                                            }
                                                            onClick={() => {
                                                                navigate(button?.path)
                                                                dispatch(setText(button?.text))
                                                                dispatch(setButton(button))
                                                            }}
                                                        >
                                                            {button?.text}
                                                        </Typography>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </div>
    )
}

export default SideNavbar
export const navButtons = navButton
